const React = require('react');
const PropTypes = require('prop-types');
const cx = require('classnames');
const { trendingUpward } = require('@forbes/fbs-icons');
const { capitalize, formatNumber, TRENDING_THRESHOLD } = require('./utils');

const AuthorInfo = ({
	author, authorGroup, difference, changeTrending, finishedChangingTrending,
}) => (
	<div className={cx('pop-picks-author', {
		'pop-picks-fade-out': changeTrending,
		'pop-picks-fade-in': finishedChangingTrending,
	})}
	>
		{difference >= TRENDING_THRESHOLD ? (
			<>
				<span
					className="pop-picks-trending-up"
					dangerouslySetInnerHTML={{ __html: trendingUpward }}
				/>
				<span className="pop-picks-author-type">
					{`${formatNumber(difference)} views in the past hour`}
				</span>
			</>
		) : (
			<>
				<span>By&nbsp;</span>
				<span className="pop-picks-author-name">
					{capitalize(author)}
				</span>
				{authorGroup && <span>&#44;&nbsp;</span>}
				<span className="pop-picks-author-type">
					{authorGroup}
				</span>
			</>
		)}
	</div>
);

AuthorInfo.propTypes = {
	author: PropTypes.string.isRequired,
	authorGroup: PropTypes.string.isRequired,
	difference: PropTypes.number.isRequired,
	changeTrending: PropTypes.bool.isRequired,
	finishedChangingTrending: PropTypes.bool.isRequired,
};

module.exports = AuthorInfo;
